/*
 * @Author: hxhong
 * @Description: 
 */
/*
 * @Author: hxhong
 * @Description: 
 */
import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-liquidfill';

interface Props {
    data: number,
    width: number,
    height: number,
}

/**
 * 图表-水波球
 * @data number, 百分比数值, 采用小数
 * @width number, 容器宽度
 * @height numebr, 容器高度
 */

const LiquidfillChart = (props: Props) => {

    const id = new Date().getTime().toString() + Math.random();

    useEffect(() => {
        let chartDom = document.getElementById(id) as HTMLElement;
        if (chartDom) {
            let myChart = echarts.init(chartDom);

            let option = {
                series: [{
                    type: 'liquidFill',
                    data: [props.data],
                    radius: '90%',
                    outline: {
                        borderDistance: 0,
                        itemStyle: {
                            borderWidth: 3,
                            borderColor: '#4992ff',
                        },
                    },
                    backgroundStyle: {
                        color: 'transparent',
                    },
                    itemStyle: {
                        shadowBlur: 0,
                    },
                    label: {
                        fontSize: '25px',
                        color: '#f8f8f8',
                        insideColor: '#f8f8f8',
                        fontWeight: 'normal',
                    },
                }]
            };

            option && myChart.setOption(option);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div id={id} style={{ width: `${props.width}px`, height: `${props.height}px` }} ></ div>
    )
}

export { LiquidfillChart }