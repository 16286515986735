

import { useState } from 'react';
import { Icon } from '@/components/icon';
import { LineLayout } from '@/layout'
import { Popover, Icon as IconAntd, Tabs, WhiteSpace, Badge } from 'antd-mobile';
import classNames from 'classnames';
import { getTimeRangeByUrl, timeRangeTypeMap } from '@/utils/time';
import { useHistory } from 'react-router-dom'
import './index.scss';
import { useMount } from 'ahooks';
interface PageBarProps {
    title: string,
    hiddenIcon?: boolean,
}

const PageBar = (props: PageBarProps) => {
    const history = useHistory()
    const clickBack = () => {
        history.goBack()
    }
    const clickToHome = () => {
        history.push('/line?lineCode=1')
    }
    return <div className='page-bar'>
        <div className='page-bar-content'>
            {
                props.hiddenIcon ? null : <div onClick={clickBack}>
                    <Icon iconName='goback.png' />
                </div>
            }
            <div className='bar-value'>{props.title}</div>

            {
                props.hiddenIcon ? null : <div onClick={clickToHome}>
                    <Icon iconName='home.png' />
                </div>
            }
        </div>

    </div>
}

interface CardBarProps {
    title: string,
    icon: string,
    size?: number,
    hideForward?: boolean,
    color?: string,
    fontWeight?: boolean,
    width?: number,
}

/**
 * 卡片标题
 * @param {string} title 标题内容 string
 * @param {string} icon 图表地址 string
 * @param {number} size 样式大小, 按比例缩放, 1为原比例 number
 * @param {boolean} hideForward 是否隐藏前进图标 boolean
 * @param {string} color 文字颜色 string
 * @param {boolean} fontWeight 文字是否加粗 boolean
 * @param {number} width 最外层宽度 number
 * @returns 
 */

const CardBar = (props: CardBarProps) => {
    return (
        <LineLayout justify='between' height={20} width={props.width === null ? 'auto' : props.width} padding={[0, 10, 0, 10]} margin={[10, 0, 10, 0]}>
            <LineLayout justify='start'>
                {
                    props.icon ? <Icon iconName={props.icon} size={props.size} /> : null
                }

                <div className='bar-value card-title' style={{ zoom: props.size, color: props.color || '#f8f8f8', fontWeight: props.fontWeight ? 'bold' : 'normal' }}>{props.title}</div>
            </LineLayout>

            {
                props.hideForward ? null : <Icon iconName='rightArrow.png' size={0.6} />
            }

        </LineLayout>
    )
}

interface RegionBarProps {
    title: string,
}

/**
 * 层级标题
 * @param {string} title - 标题内容 string
 * @returns 
 */

const RegionBar = (props: RegionBarProps) => {
    return (
        <div className='bar-value region-container'>
            {props.title}
        </div>
    )
}
interface TimeBarProps {
    click?: Function,
    change?: Function,
    size?: number,
    height?: number,
    selected?: number,
}

/**
 * 时间栏
 * @param {Function} click 点击回调函数 Function
 * @param {Function} change 改变回调函数 Function
 * @param {number} size 样式大小, 1表示原比例 number
 * @param {number} height 高度 number
 * @param {number} selected 选中的时间选项编号 string
 * @returns 
 */

const TimeBar = (props: TimeBarProps) => {
    const [visible, setVisible] = useState(false);
    const [selected, setSelected] = useState(0);
    const [popoverContent, setPopoverContent] = useState('今年(按周)');
    const tabs = [
        { name: '今日', timeRangeType: 'today' },
        { name: '本周', timeRangeType: 'week' },
        { name: '本月', timeRangeType: 'month' },
        { name: '今年 (按周)', timeRangeType: 'yearByWeek', isPopover: true },
        { name: '今年(按月)', timeRangeType: 'yearByMonth', isPopover: true },
        { name: '今年(按季度)', timeRangeType: 'yearByQuarter', isPopover: true },
        { name: '累积', timeRangeType: 'totalByYear', isPopover: true },
        { name: '上周', timeRangeType: 'lastWeek', isPopover: true },
        { name: '上月', timeRangeType: 'lastMonth', isPopover: true },
    ]
    const handleVisibleChange = (visible: boolean) => {
        setVisible(visible);
    };
    const handleClickChangeTab = (item: any, idx: number) => {
        // console.log(timeRangeTypeMap)
        // console.log(timeRangeTypeMap[item.timeRangeType])
        if (props.change) {
            props.change(timeRangeTypeMap[item.timeRangeType], item.idx)
        }
        setSelected(idx)
        setVisible(false);
        if (item.isPopover) {
            setPopoverContent(item.name)
            setSelected(3)
        }
    }
    useMount(() => {
        if (props.change) {
            props.change(getTimeRangeByUrl())
        }
    })
    return (
        <div className='bar-value' style={{ height: `${props.height}px` }}>
            <LineLayout justify='around' height={25} padding={[0, 10, 0, 10]}>

                {
                    tabs.filter((item: any, idx: number) => idx < 3).map((item: any, idx: number) => {
                        return <div onClick={() => handleClickChangeTab(item, idx)}
                            className={classNames({ 'time-select': idx === selected })}
                            style={{ zoom: props.size || 1 }}>{item.name}
                        </div>
                    })
                }
                <Popover mask
                    overlayClassName="fortest"
                    overlayStyle={{ color: 'currentColor' }}
                    visible={visible}
                    overlay={tabs.filter((item: any, idx: number) => idx > 2).map((item: any, idx: number) => {
                        return <Popover.Item key={idx + 3}  >{item.name}</Popover.Item>
                    })}

                    align={{
                        overflow: { adjustY: 0, adjustX: 0 },
                        //@ts-ignore
                        offset: [-10, 5],
                    }}
                    placement='bottom'
                    onVisibleChange={handleVisibleChange}
                    onSelect={(opt: any) => handleClickChangeTab(tabs.find((item: any, idx: number) => parseInt(opt.key) === idx), opt.key)}
                >
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span className={classNames({ 'time-select': selected === 3 })} style={{ zoom: props.size || 1 }}>{popoverContent}</span><IconAntd type={'down'} />
                    </div>
                </Popover>
            </LineLayout>
        </div>
    )
}

const TimeBar2 = (props: any) => {
    const tabs = [
        { title: '今日', timeRangeType: 'today' },
        { title: '本周', timeRangeType: 'week' },
        { title: '本月', timeRangeType: 'month' },
        { title: <Badge text={'按周'}>今年</Badge>, timeRangeType: 'yearByWeek' },
        { title: <Badge text={'按月'}>今年</Badge>, timeRangeType: 'yearByMonth' },
        { title: <Badge text={'按季度'}>今年</Badge>, timeRangeType: 'yearByQuarter' },
        { title: '累积', timeRangeType: 'totalByYear' },
        { title: '上周', timeRangeType: 'lastWeek' },
        { title: '上月', timeRangeType: 'lastMonth' },
    ];

    return (
        <div>
            <WhiteSpace />
            <Tabs tabs={tabs} renderTabBar={props => <Tabs.DefaultTabBar {...props} page={4}
                tabBarBackgroundColor='#000'
                tabBarTextStyle={{ fontSize: '17.5px', fontWeight: 'normal' }}
                tabBarUnderlineStyle={{ borderColor: '#4992FF' }}
                tabBarActiveTextColor={'#4992FF'}
                tabBarInactiveTextColor={'#fff'}
                onTabClick={(item: any, index: number) => { console.log('已经点击', item, index) }}
            />}>
            </Tabs>
            <WhiteSpace />
        </div>
    )
}
export { PageBar, CardBar, RegionBar, TimeBar, TimeBar2 }
