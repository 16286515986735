
import { CardBar } from '@/components/bar';
import { BasicLayout, CenterLayout, EasyGridLayout } from '@/layout';
import { DigitalBlock } from '@/components/digitalBlock'
import * as Thumbmail from '@/components/thumbnail'
import { useRequest } from 'ahooks';
import * as API from '@/api/request'
import { Loading1 } from '@/components/loading'
import { Error } from '@/components/error';
import { Card } from '@/components/card';
import { store } from '@/store';
export const Home = () => {
    const { data, loading, error } = useRequest(() => API.Production.getTotalCount())
    if (loading) return <Loading1 />
    if (error) return <Error />
    const totalCount = data
    const installLineCodes = store.getState().installLineCodes
    const topCards = [
        { title: '生产', icon: 'production.png', content: <Thumbmail.Production />, jumpTo: '/workShop/production' },
        { title: '质量', icon: 'quality.png', content: <Thumbmail.Quality />, jumpTo: '/workShop/quality' },
        { title: '设备', icon: 'equipment.png', content: <Thumbmail.Equipment />, jumpTo: '/workShop/equipment' },
        { title: '环境', icon: 'env.png', content: <Thumbmail.Environment />, jumpTo: '/workShop/environment' }
    ]

    const pageName = store.getState().projectInfo.name
    return <BasicLayout name={pageName}>
        <CenterLayout height={40} width={355} padding={[0, 20, 0, 10]} margin={[10, 0, 10, 0]} radius={5}>
            <DigitalBlock name='累计产量' unit='双' value={totalCount} size={0.8} spacing={20} />
        </CenterLayout>
        <EasyGridLayout rows={2} columns={2} width={350} height={440} gap={10} >
            {
                topCards.map(item => {
                    return <Card title={item.title} icon={item.icon} jumpTo={item.jumpTo} center='horizontal' children={item.content} />
                })
            }
        </EasyGridLayout>
        <CardBar title='全部产线' icon='' hideForward />
        <EasyGridLayout rows={3} columns={2} width={350} height={480} gap={10} >

            {
                installLineCodes.map((item: any) => {
                    return <Card title={`产线${item}`} jumpTo={`/line?lineCode=${item}`}  >
                        <Thumbmail.Line lineCode={item} />
                    </Card>
                })
            }

        </EasyGridLayout>
    </BasicLayout>
}