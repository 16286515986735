import React from 'react';
import _ from 'lodash'
import './index.scss';

//基本数值处理
function handleNumberValue(value: number | string, retentionMode: any, retentionDigit: any) {
    if (value === null) return '--'
    let data = Number(value);
    let mode = retentionMode || 'round';
    if (data > 9999999) {
        data = _[mode](data / 10000, retentionDigit ? retentionDigit : 0);
        return parseFloat(data.toString()).toLocaleString();
    }
    if (data > 9999) {
        return _[mode](data / 10000, retentionDigit === undefined ? 1 : retentionDigit);
    }
    if (data > 999) {
        return parseFloat((_[mode](data, retentionDigit ? retentionDigit : 0))).toLocaleString();
    }
    return _[mode](data, retentionDigit ? retentionDigit : 0);
}

//数值颜色处理
function handleValueColor(minAllow: any, maxAllow: any, value: number | string, color: any) {
    if ((minAllow && minAllow > value) || (maxAllow && maxAllow < value)) {
        return '#FF0000'
    }
    if (color) {
        return color;
    }
    return '#05D380';
}

interface Props {
    type?: 'transverse' | 'nameStacking' | 'valueStacking',
    name?: string,
    value: number | string,
    unit?: string,
    color?: string,
    minAllow?: number,
    maxAllow?: number,
    spacing?: number,
    size?: number,
    valueType?: 'normal' | 'time',
    textAlign?: 'left' | 'center' | 'right',
    retentionMode?: 'round' | 'floor' | 'ceil',
    retentionDigit?: number,
    fontSize?: number,
    width?: number,
}

/**
 * 数字块
 * @name 标题
 * @value 数值
 * @unit 单位
 * @color 数值颜色
 * @minAllow 最小允许值
 * @maxAllow 最大允许值
 * @spacing 标题与数值间距
 * @size 样式大小, 按比例缩放, 1为原比例
 * @valueType 数值类型
 * @textAlign 堆叠模式时文字对齐方式
 * @retentionMode 数字处理方式
 * @retentionDigit 数字精确位数
 * @fontSize 数字字体大小
 * @width 默认模式和nameStacking可用,数字块宽度
 */


const DigitalBlock = (props: Props) => {

    let value;
    if (props.valueType === 'time') {
        value =
            <React.Fragment>
                {props.value < 1 ?
                    <>
                        <span className='digitalBlock-value' style={{ color: handleValueColor(props.minAllow, props.maxAllow, props.value, props.color) }}>
                            {Math.floor(Number(props.value) * 60)}
                        </span> 分钟
                    </> :
                    <>
                        <span className='digitalBlock-value' style={{ color: handleValueColor(props.minAllow, props.maxAllow, props.value, props.color) }}>
                            {(Math.floor(Number(props.value)))}</span> 小时 <span className='digitalBlock-value' style={{ color: handleValueColor(props.minAllow, props.maxAllow, props.value, props.color) }} >{Math.floor(Number(props.value) % 1 * 60)}
                        </span> 分
                    </>
                }
            </React.Fragment >
    } else {
        value =
            <React.Fragment>
                <span className='digitalBlock-value' style={{ color: handleValueColor(props.minAllow, props.maxAllow, props.value, props.color), fontSize: `${props.fontSize || 20}px` }}>
                    {handleNumberValue(props.value, props.retentionMode, props.retentionDigit)}
                </span>
                {props.value > 9999 ? `万${props.unit || ''}` : props.unit || ''}
            </React.Fragment>
    }

    if (props.type === 'transverse' || !props.type) {
        return (
            <div className='digitalBlock-container' style={{ zoom: props.size || 1, width: props.width === null ? 'auto' : props.width + 'px', textAlign: props.textAlign }}><span style={{ marginRight: `${props.spacing}px` }}>{props.name}</span> {value}</div>
        )
    }

    if (props.type === 'nameStacking') {
        return (
            <div className='digitalBlock-container' style={{ zoom: props.size || 1, textAlign: props.textAlign, width: props.width === null ? 'auto' : props.width + 'px', }}>
                <div style={{ marginBottom: `${props.spacing}px` }}>{props.name}</div>
                <div>{value}</div>
            </div>
        )
    }

    if (props.type === 'valueStacking') {
        return (
            <div className='digitalBlock-container' style={{ zoom: props.size || 1, textAlign: props.textAlign }}>
                <div style={{ marginBottom: `${props.spacing}px` }}>{value}</div>
                <div>{props.name}</div>
            </div>
        )
    }

    return null;
}

export { DigitalBlock };