import { EasyGridLayout } from '@/layout'
import { DigitalBlock } from '@/components/digitalBlock'
import { useRequest } from 'ahooks'
import * as API from '@/api/request'
import { searchParams } from '@/utils'
import { Error } from '../error'
import { round } from 'lodash'
import { Loading2 } from '../loading'
export const Environment = () => {
    const { loading, data, error } = useRequest(() => API.Environment.getCurrentEnvironmentValues(searchParams().lineCode || null))
    if (loading) return <Loading2 />
    if (error) return <Error size={0.5} />
    let digitals = [
        { name: '温度', unit: '℃', value: round(data.temperatureInC) },
        { name: '湿度', unit: '%', value: round(data.humidness * 100) },
        { name: '噪音', unit: 'dB', value: round(data.noiseIndB) },
        { name: 'pM2.5', unit: 'ug/m3', value: round(data.humidness) }
    ]
    return <EasyGridLayout rows={2} columns={2} width={150} height={160} splitLine={true} center  >
        {
            digitals.map((item: any, idx: number) =>
                <DigitalBlock key={idx} type='nameStacking' name={item.name} value={item.value} unit={item.unit} size={0.7} spacing={5} fontSize={30} />)
        }
    </EasyGridLayout>
}